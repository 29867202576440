// src/components/Footer.js

import React from 'react';
import './footer.css'; // Import the CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container mx-auto text-center">
                <div className="copyright">
                    <p>© 2024 JXCode.id All rights reserved.</p>
                    <p>Contact us: contact@jxcode.com</p>
                    <p>Follow us on social media: Facebook | Twitter | Instagram</p>
                </div>
                <div className='social'>
                    <p>© JXJXJJXX.</p>
                    <p>© JXJXJJXX.</p>
                    <p>© JXJXJJXX.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
