// src/components/HomePage.js

import React from 'react';
import './homepage.css'; // Import the CSS file

const HomePage = () => {
    return (
        <div className="homepage p-4 space-y-8"> {/* Overall layout with padding and spacing */}
            
            {/* Overview Section */}
            <section className="overview bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">Overview Company</h2>
                <p>Welcome to our company! We specialize in delivering top-notch services to our clients.</p>
            </section>

            {/* Services Section */}
            <section className="services bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">Our Services</h2>
                <ul className="list-disc list-inside space-y-1">
                    <li>Service 1</li>
                    <li>Service 2</li>
                    <li>Service 3</li>
                    <li>Service 4</li>
                </ul>
            </section>

            {/* Engineers Carousel Section */}
            <section className="engineers bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">Our Engineers</h2>
                <div className="carousel space-x-4 overflow-x-auto">
                    {Array.from({ length: 12 }, (_, index) => (
                        <div key={index} className="engineer-card border rounded p-4 bg-gray-100 w-40 flex-shrink-0">
                            <p className="font-semibold">Engineer {index + 1}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Pricing Section */}
            <section className="pricing bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">Pricing Packages</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="package border rounded p-4 bg-gray-100">
                        <h3 className="font-semibold">Basic Package</h3>
                        <p>$99/month</p>
                    </div>
                    <div className="package border rounded p-4 bg-gray-100">
                        <h3 className="font-semibold">Standard Package</h3>
                        <p>$199/month</p>
                    </div>
                    <div className="package border rounded p-4 bg-gray-100">
                        <h3 className="font-semibold">Premium Package</h3>
                        <p>$299/month</p>
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="testimonials bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">Testimonials</h2>
                <p>We have collaborated with:</p>
                <ul className="list-disc list-inside">
                    <li>Company A</li>
                    <li>Company B</li>
                    <li>Company C</li>
                </ul>
            </section>

            {/* Tech Stack Section */}
            <section className="tech-stack bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">Tech Stack</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="tech-item bg-gray-100 p-4 rounded">Tech 1</div>
                    <div className="tech-item bg-gray-100 p-4 rounded">Tech 2</div>
                    <div className="tech-item bg-gray-100 p-4 rounded">Tech 3</div>
                    <div className="tech-item bg-gray-100 p-4 rounded">Tech 4</div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="faq bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">FAQ</h2>
                <p>Frequently Asked Questions:</p>
                <ul className="list-disc list-inside">
                    <li>What is your refund policy?</li>
                    <li>How do I contact support?</li>
                    <li>What services do you provide?</li>
                </ul>
            </section>

            {/* Founder Section */}
            <section className="founder bg-white border border-gray-300 p-6 rounded shadow-md">
                <h2 className="text-2xl font-bold mb-2">Founder History</h2>
                <p>Learn more about our founder and the journey of the company.</p>
            </section>

        </div>
    );
};

export default HomePage;
