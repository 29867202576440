import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import HomePage from './components/homepage';
import Footer from './components/footer';

function App() {
  return (
      <Router>
          <div className="flex flex-col min-h-screen"> {/* Flex container to manage height */}
              <Header />
              <main className="flex-grow overflow-y-auto pt-16 pb-4"> {/* Adjust padding to match header height */}
                  <Routes>
                      <Route path="/" element={<HomePage />} />
                      {/* Add more routes here */}
                  </Routes>
              </main>
              <Footer />
          </div>
      </Router>
  );
}

export default App;