// src/components/Header.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css'; // Import the CSS file

const Header = () => {
    const [servicesOpen, setServicesOpen] = useState(false);
    const [companyOpen, setCompanyOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu

    return (
        <header className="bg-white shadow-md fixed w-full z-10">
            <div className="container mx-auto flex justify-between items-center p-4">
                {/* Logo */}
                <div className="logo">
                    <h1 className="text-2xl font-bold">JXCode.id</h1>
                </div>

                {/* Hamburger Menu for Mobile */}
                <div className="md:hidden">
                    <button onClick={() => setMenuOpen(!menuOpen)} className="focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>

                {/* Navigation */}
                <nav className={`flex-col md:flex md:flex-row md:space-x-8 ${menuOpen ? 'flex' : 'hidden'} md:flex`}>
                    {/* Services Dropdown */}
                    <div className="relative">
                        <button onClick={() => setServicesOpen(!servicesOpen)} className="flex items-center">
                            <span className="hover:text-blue-500">Services</span>
                            <span className="ml-1 text-xs">▼</span>
                        </button>
                        {servicesOpen && (
                            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-20">
                                <Link to="/service1" className="block px-4 py-2 hover:bg-gray-100">Service 1</Link>
                                <Link to="/service2" className="block px-4 py-2 hover:bg-gray-100">Service 2</Link>
                                <Link to="/service3" className="block px-4 py-2 hover:bg-gray-100">Service 3</Link>
                            </div>
                        )}
                    </div>

                    {/* Technologies Dropdown */}
                    <div className="relative">
                        <button onClick={() => setCompanyOpen(!companyOpen)} className="flex items-center">
                            <span className="hover:text-blue-500">Company</span>
                            <span className="ml-1 text-xs">▼</span>
                        </button>
                        {companyOpen && (
                            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-20">
                                <Link to="/about" className="block px-4 py-2 hover:bg-gray-100">About Us</Link>
                                <Link to="/team" className="block px-4 py-2 hover:bg-gray-100">Our Team</Link>
                                <Link to="/careers" className="block px-4 py-2 hover:bg-gray-100">Careers</Link>
                            </div>
                        )}
                    </div>

                    <Link to="/testimonials" className="hover:text-blue-500">Testimonials</Link>
                    <Link to="/tech-stack" className="hover:text-blue-500">Tech Stack</Link>
                </nav>

                {/* Contact Us Button */}
                <div>
                    <Link to="/contact" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Contact Us</Link>
                </div>
            </div>

            {/* Mobile Menu Toggle */}
            {menuOpen && (
                <div className="md:hidden flex flex-col bg-white border border-gray-300 p-4 rounded shadow-md">
                    <Link to="/" className="block py-2 hover:bg-gray-100">Home</Link>
                    <Link to="/about" className="block py-2 hover:bg-gray-100">About</Link>
                    <Link to="/services" className="block py-2 hover:bg-gray-100">Services</Link>
                    <Link to="/testimonials" className="block py-2 hover:bg-gray-100">Testimonials</Link>
                    <Link to="/tech-stack" className="block py-2 hover:bg-gray-100">Tech Stack</Link>
                    <Link to="/contact" className="block py-2 hover:bg-gray-100">Contact Us</Link>
                </div>
            )}
        </header>
    );
};

export default Header;
